import StoryblokClient, { StoriesParams } from 'storyblok-js-client';

const BASE_STORIES = 'cdn/stories/';

export type Datasource = {
  id: string;
  name: string;
  value: string;
  dimension_value?: string;
};

export type GetStoriesArguments = {
  path?: string;
  queryParameters?: StoriesParams & { datasource?: string };
};

export type GetStoriesReturnType<T> = Promise<null | void | {
  data: StoryblokEntry<T>[];
  perPage: number;
  total: number;
}>;

const getStoryblokClient = () => {
  return new StoryblokClient({
    accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
  });
};
/**
 * Exclude news & blog archive pages from the fetched stories.
 * Resources archives don't need to be excluded, because resources queries are always using
 * precise by_slug parameter setting, like by_slugs: "en/resources/data-sheet/, en/resources/e-book/"
 * Events archives don't need to be excluded, because event archive url structure is event/archive-list-event, while
 * the events queries for /events/*
 */
const excludeArchiveSlugs = ({ queryParameters }: GetStoriesArguments) => {
  const archiveSlugs =
    'en/news/archive-list-news,en/blog/archive-list-blog,dev-only/news/archive-list-news,dev-only/blog/archive-list-blog,en/events/archive-list-event,en/webinar/archive-list-webinar';
  return {
    ...queryParameters,
    excluding_slugs: queryParameters?.excluding_slugs
      ? `${queryParameters.excluding_slugs},${archiveSlugs}`
      : archiveSlugs,
  };
};

const fetchStories = async <T>(
  storyblok: StoryblokClient,
  fullPath: string,
  modifiedQueryParameters: StoriesParams,
): GetStoriesReturnType<T> => {
  let retries = 0;
  const MAX_RETRIES = 1;

  while (retries < MAX_RETRIES) {
    try {
      const response = await storyblok.get(fullPath, modifiedQueryParameters);

      if (response.total !== 0) {
        return {
          data: response.data.stories || response.data.story,
          total: response.total,
          perPage: response.perPage,
        };
      }

      console.warn(`Retrying request... Attempt ${retries + 1}`);
      retries++;
    } catch (error) {
      console.error(error);
      return {
        data: [],
        total: 0,
        perPage: modifiedQueryParameters.per_page || 6,
      };
    }
  }

  console.log('Max retries reached. Returning null.');
  return {
    data: [],
    total: 0,
    perPage: modifiedQueryParameters.per_page || 6,
  };
};

export const getStories = <T>({ path = '', queryParameters }: GetStoriesArguments): GetStoriesReturnType<T> => {
  const storyblok = getStoryblokClient();
  const fullPath = path || BASE_STORIES;
  const modifiedQueryParameters = excludeArchiveSlugs({ queryParameters });

  return fetchStories(storyblok, fullPath, modifiedQueryParameters);
};
