import React, { useState, useEffect, useMemo, useContext } from 'react';
import { LanguageContext } from '@/base/context/Language/provider';
import { getStories } from '@/base/services/storyblok';
import RelatedPostsList from '../RelatedPostsList';
import { PostCardTypes } from '../PostCard/PostCard.types';
import { Filter, RelatedPostsTypes } from './RelatedPosts.types';

export default function RelatedPosts({
  slug,
  title,
  filters,
  label,
  onlyResources,
  className,
  isDataTypeLiveWebinar,
  ...props
}: RelatedPostsTypes) {
  const {
    state: { language },
  } = useContext(LanguageContext);

  const [relatedPosts, setRelatedPosts] = useState<StoryblokEntry<PostCardTypes>[]>([]);

  const unusedSlugs = `${language}/blog/*,${language}/news/*`;

  const sourceSlug = useMemo(() => {
    if (slug.split('/').length === 1) return slug;
    const path = slug.split('/');
    return path[1];
  }, [slug]);

  function normalizeFilters(selectedFilters?: Filter) {
    if (!selectedFilters) return {};

    return Object.keys(selectedFilters).reduce((prevState, currenState) => {
      if (
        !selectedFilters[currenState]?.in?.length &&
        !selectedFilters[currenState]?.in_array?.length &&
        !selectedFilters[currenState]?.not_in?.length &&
        !selectedFilters[currenState]?.any_in_array?.length
      )
        return prevState || {};
      return { ...(prevState || {}), [currenState]: selectedFilters[currenState] };
    }, {});
  }

  const fetchRelatedCards = () =>
    getStories<PostCardTypes>({
      queryParameters: {
        // @ts-ignore - API INCOMPABILITY
        resolve_relations: ['template_blog_post.author'],
        filter_query: normalizeFilters(filters),
        per_page: 3,
        is_startpage: 0,
        excluding_slugs: `${slug},${onlyResources ? unusedSlugs : ''}`,
        starts_with: isDataTypeLiveWebinar ? `${language}` : `${language}${'/' + sourceSlug}`,
        sort_by: 'first_published_at:desc',
      },
    });

  useEffect(() => {
    fetchRelatedCards().then(response => {
      if (!response?.data.length || !Array.isArray(response.data)) return null;
      return setRelatedPosts(response.data);
    });
  }, [language, slug, filters]);

  return <RelatedPostsList title={title} className={className} posts={relatedPosts} label={label} {...props} />;
}
